<script>
import distribution from './distribution.vue'
import common from './common.vue'
import { mapGetters } from 'vuex'
import { ROLES } from '@/constants/constants'
export default {
  name: 'Distribution',
  components: { distribution, common },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit('app/addKeepAlive', 'Distribution')
    })
  },
  beforeRouteLeave(to, from, next) {
    // 如果回到销售助手（店端/总部）、交付助手首页 清空过滤数据
    if (['/delivery-assistant','/salesAssistant','/sales-assistant-portal'].some((item) => { return to.path.includes(item) })) {
      if (this.isShopManage) {
        this.$refs.componentName[0].filterPanelVisible = false
        this.$refs.componentName[0].filterParams = {}
        this.$refs.componentName[0].$refs.filterPanel.reset()
      } else {
        this.$refs.componentName.filterPanelVisible = false
        this.$refs.componentName.filterParams = {}
        this.$refs.componentName.$refs.filterPanel.reset()
      }
      this.$store.commit('app/deleteKeepAlive', 'Distribution')
    }
    next()
  },
  data() {
    const that = this
    return {
      type: 0,
      tabs: [
        {
          name: that.$t('待分配线索'),
          value: 0,
          componentName: 'distribution'
        },
        {
          name: that.$t('超期线索'),
          value: 1,
          componentName: 'common'
        }
      ],
      outsideTabs: []
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    outsideTab() {
      return this.outsideTabs.slice(1)
    },
    // 是否有店长权限
    isShopManage() {
      const { roleLogos = [] } = this.userInfo
      return roleLogos.some((item) => ROLES.SHOWOWNER === item)
    },
  },
  activated() {
    if (this.isShopManage) {
      this.$refs.componentName[0].onRefresh()
      this.$refs.componentName[0].removeCheck()
    } else {
      this.$refs.componentName.onRefresh()
      this.$refs.componentName.removeCheck()
    }
  },
  created() {
    const { tabs } = this.$route.query
    if (tabs) {
      this.outsideTabs = tabs.split(',')
    }
    this.type = Number(this.outsideTabs[0] || 0)
  },
  methods: {
    onTabChange() {
      this.outsideTabs = []
    }
  }
}
</script>
<template>
  <van-tabs v-if="isShopManage" v-model="type" class="tabs1" @change="onTabChange">
    <van-tab v-for="item in tabs" :key="item.value" :title="item.name" :name="item.value">
      <component :is="item.componentName" v-if="item.value === type" ref="componentName" :outsideTab="outsideTab" />
    </van-tab>
  </van-tabs>
  <component :is="'common'" v-else ref="componentName" :outsideTab="outsideTab" />
</template>
<style lang="less">
  .tabs1{
    >div {
      &.van-tabs__wrap{
        background: #fff;
        box-sizing: border-box;
        position: sticky;
        z-index: 20;
        top: 0
      }
    }
  }
</style>
